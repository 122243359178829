import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  Filter,
  Page,
  Edit,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { DialogUtility } from "@syncfusion/ej2-react-popups";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";

import validator from "validator";

import { Header } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";

import {
  entityButtonGrid,
  entityButtonDelete,
  entityButtonsData,
  sortButtonEntities,
} from "../../data/posmenu";
import {
  entityData,
  entityEdit,
  entityAdd,
  tileRender,
  globalTypesData,
  entityCopy,
} from "../../data/posmenu";
import { MiddleColours, MenuColumns, MenuLines } from "../../tools/menuEntity";
import { ShowToast } from "../../tools/extras";
import "./../CSS/POSMenu.css";

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import "./../CSS/dnd.css";

const POSButton = () => {
  const { currentMode } = useStateContext();
  const bgcolor = currentMode === "Dark" ? "bg-gray-700" : "bg-white";

  const gridcomp = useRef();
  const confirmObj = useRef();

  const refGlobalType = useRef();
  const refColumns = useRef();
  const refLine = useRef();

  const [EntityData, setEntity] = useState([]);
  const [EntityButtonData, setEntityButton] = useState([]);
  const [GlobalTypeData, setGlobalTypes] = useState([]);

  const [selectedColour, setSelectedColour] = useState("#eeeeee");
  const [selectedLine, setLine] = useState(40);
  const [selectedColumn, setColumn] = useState(174);
  const [selectedGlobalType, setGlobalType] = useState();

  //id:catalogIID
  //iid:entityIID
  const { catalogueIID, entityIID } = useParams();
  const navigate = useNavigate();

  let GlobalTypeFields = { text: "GlobalTypeName", value: "IID" };
  let fields = { text: "text", value: "value" };

  const getGlobalTypes = () => {
    globalTypesData(catalogueIID, (dataorder) => {
      setGlobalTypes(dataorder);
      if (dataorder.length > 0 && entityIID === "new") {
        setGlobalType(dataorder[0].IID);
        MenuItemCheck();
      } else {
        GetEntity();
        getEntityButtons();
      }
    });
  };

  let GetEntity = () => {
    if (entityIID !== "new") {
      entityData(entityIID, (dataorder) => {
        setEntity(dataorder);
        setGlobalType(dataorder.GlobalTypeIID);
        setLine(dataorder.ButtonHeight);
        setColumn(dataorder.ButtonWidth);
        setSelectedColour(dataorder.PanelColor);

        MenuItemCheck(dataorder.EntityType);
      });
    } else {
      MenuItemCheck();
    }
  };

  const changeColour = (args) => {
    //console.log(args.target.element.parentElement.parentElement.style.backgroundColor)
    args.target.element.parentElement.parentElement.style.backgroundColor =
      args.value;
    setSelectedColour(args.value);
  };

  const getEntityButtons = () => {
    entityButtonsData(entityIID, (dataorder) => {
      setEntityButton(dataorder); // leading data to the ListBox
    });
  };

  const entityChange = () => {
    if (gridcomp.current != null && gridcomp.current.selectedRowIndex > -1) {
      selectedRow = gridcomp.current.getSelectedRecords()[0];
    }
    if (selectedRow != null && entityIID !== "new") {
      navigate("/posbutton/" + entityIID + "/" + selectedRow.IID);
    } else {
      ShowToast({ content: `Please select an item first` });
    }
  };

  const entityNew = () => {
    if (entityIID !== "new") {
      navigate("/posbutton/" + entityIID + "/new");
    }
  };

  const entityButtonDel = () => {
    if (gridcomp.current != null && gridcomp.current.selectedRowIndex > -1) {
      selectedRow = gridcomp.current.getSelectedRecords()[0];
    }
    if (selectedRow != null && entityIID !== "new") {
      entityButtonDelete(selectedRow.IID, () => {
        getEntityButtons(catalogueIID);
        confirmObj.current.hide();
        ShowToast({ content: `It's deleted!`, type: "success" });
      });
    }
  };

  const DeleteConfirm = () => {
    if (gridcomp.current != null && gridcomp.current.selectedRowIndex > -1) {
      selectedRow = gridcomp.current.getSelectedRecords()[0];
    }
    if (selectedRow != null && entityIID !== "new") {
      confirmObj.current = DialogUtility.confirm({
        //cancelButton: { text: 'Cancel', click: ()=>{console.log("gave up")} },
        closeOnEscape: false,
        content:
          "When you delete it, there is no option to get it back. Please think twice before deleting anything!",
        okButton: { text: "DELETE IT", click: entityButtonDel.bind(this) },
        showCloseIcon: true,
        title: "Do you really want to delete it?",
      });
    } else {
      ShowToast({ content: `Please select an item first` });
    }
  };

  useEffect(() => {
    getGlobalTypes();
  }, []);

  const saveEntity = () => {
    var obj = validateModalItems();

    if (obj.EntityName === undefined) return false;

    if (entityIID !== "new") {
      //record check
      entityEdit(obj, (x) => {
        //getEntity();
        navigate("/posmenu/" + catalogueIID + "/" + entityIID);
        ShowToast({ content: `${obj.EntityName} is changed`, type: "success" });
      });
    } else {
      entityAdd(obj, (x) => {
        navigate("/posmenu/" + catalogueIID + "/" + x.iid);
        //getEntity();
        ShowToast({ content: ` ${obj.EntityName} is edited`, type: "success" });
      });
    }
  };
  const copyEntity = () => {
    ShowToast({ content: ` its not ready yet`, type: "warning" });
    /*
    var obj = validateModalItems();
    entityCopy(obj,
      (x)=>{       
      //getEntity();
      navigate("/posmenu/"+catalogueIID+"/"+x.iid); 
    });
    //ShowToast({content:'Successifully Copied!',type:'succeess'})
    */
  };
  const cancelEntity = () => {
    navigate("/posmenu");
  };

  const MenuItemCheck = (EntityType = 0) => {
    if (EntityType === 1) {
      document.getElementById("MenuItem").checked = false;
      document.getElementById("DiscItem").checked = true;
    } else {
      document.getElementById("MenuItem").checked = true;
      document.getElementById("DiscItem").checked = false;
    }
  };

  const moreGlobalType = () => {
    navigate("/posprint/" + catalogueIID);
  };

  const cleanValidateModalItems = () => {
    //document.getElementById('errPassword').innerText=('');
    document.getElementById("errEntityName").innerText = "";
  };

  const validateModalItems = () => {
    let obj = {
      IID: entityIID === "new" ? "" : entityIID,
      EntityName: document.getElementById("entityName").value.trim(),
      PanelColor: selectedColour,
      ButtonHeight: refLine.current.value,
      ButtonWidth: refColumns.current.value,
      GlobalTypeName: refGlobalType.current.text,
      GlobalTypeIID: refGlobalType.current.value,
      catalogIID: catalogueIID,
      EntityType: document.querySelector('input[name="EntityType"]:checked')
        .value,
    };
    let err = false;

    if (!validator.isLength(obj.EntityName, { min: 3, max: 20 })) {
      document.getElementById("errEntityName").innerText =
        "Category can only be Alpha Numeric characters. Min:3 and Max:20 characters";
      err = true;
    }
    return err ? {} : obj;
  };

  /*menu Drag Drop Start*/
  const [visButon, setVisButon] = useState(false);
  let selectedRow = null;
  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    if (sourceIndex == targetIndex) return;
    console.log(sourceIndex, targetIndex);
    const nextState = swap(EntityButtonData, sourceIndex, targetIndex);
    setEntityButton(nextState);
    gridcomp.dataSource = nextState;
    sortButtonEntities(
      nextState.map((item, index) => {
        return { IID: item.IID, DisplayOrder: index };
      })
    );
  }
  var selectedBtn = null;
  function clickSortItem(event) {
    document
      .querySelectorAll(".grid-item-content")
      .forEach((element) => element.classList.remove("btnBorder"));

    selectedRow = EntityButtonData.find((item) => item.IID == event.target.id);
    selectedBtn = event.target;
    selectedBtn.classList.add("btnBorder");
    //console.log(event.target.id,selectedRow);
  }

  const optButton = (event) => {
    if (visButon == false) {
      setVisButon(true);
      event.target.innerText = "List";
    } else {
      setVisButon(false);
      event.target.innerText = "Button";
    }
    selectedRow = null;
  };

  /*menu Drag Drop End*/

  return (
    <div className={"mx-5 rounded-2xl " + bgcolor}>
      <Header title={EntityData.EntityName} />
      <form id="form1">
        <div className="mt-10 grid grid-cols-3 gap-x-6 gap-2">
          <button
            type="button"
            id="btnCancelEntity"
            onClick={cancelEntity}
            className="e-btn bg-zinc-200 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded "
          >
            BACK{" "}
          </button>
          <button
            type="button"
            id="btnSaveEntity"
            onClick={saveEntity}
            className="e-btn bg-zinc-200 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded "
          >
            SAVE{" "}
          </button>
          <button
            type="button"
            id="btnSaveEntity"
            onClick={copyEntity}
            className="e-btn bg-zinc-200 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded "
          >
            SAVE AS{" "}
          </button>

          <label
            htmlFor="entityName"
            className="block text-sm font-medium leading-6 text-gray-900 "
          >
            Category Name
          </label>
          <input
            id="entityName"
            type="text"
            defaultValue={EntityData.EntityName}
            className="block flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 col-span-2 "
          ></input>
          <div id="errEntityName" className="col-span-3 text-red-700" />

          <label
            htmlFor="EntityType"
            className="block text-sm font-medium leading-6 text-gray-900 "
          >
            Category Type
          </label>

          <div className="e-btn-group ">
            <input type="radio" id="MenuItem" name="EntityType" value="0" />
            <label className="e-btn" htmlFor="MenuItem">
              Menu Item
            </label>
            <input type="radio" id="DiscItem" name="EntityType" value="1" />
            <label className="e-btn" htmlFor="DiscItem">
              Discount{" "}
            </label>
          </div>
          <div></div>

          <label
            htmlFor="colour"
            className="block text-sm font-medium leading-6 text-gray-900 "
          >
            Default Colour
          </label>
          <div
            className="colorPalette w-20 border rounded-lg  col-span-2"
            style={{ backgroundColor: selectedColour }}
          >
            <ColorPickerComponent
              presetColors={MiddleColours}
              columns={7}
              mode="Palette"
              modeSwitcher={false}
              showButtons={false}
              value={selectedColour}
              onChange={changeColour.bind(this)}
              beforeTileRender={tileRender}
            />
          </div>

          <label
            htmlFor="ColomnVal"
            className="block text-sm font-medium leading-6 text-gray-900 "
          >
            Colomns
          </label>
          <DropDownListComponent
            ref={refColumns}
            id="ColumnsVal"
            dataSource={MenuColumns}
            value={selectedColumn}
            fields={fields}
            className="block flex-1 border-1 border-slate-200 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
          <div></div>

          <label
            htmlFor="LineVal"
            className="block text-sm font-medium leading-6 text-gray-900 "
          >
            Line
          </label>

          <DropDownListComponent
            ref={refLine}
            id="LineVal"
            dataSource={MenuLines}
            value={selectedLine}
            fields={fields}
            className="block flex-1 border-1 border-slate-200 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
          <div></div>

          <label
            htmlFor="GlobalType"
            className="block text-sm font-medium leading-6 text-gray-900 "
          >
            Print Name
          </label>

          <DropDownListComponent
            ref={refGlobalType}
            id="GlobalType"
            dataSource={GlobalTypeData}
            value={selectedGlobalType}
            fields={GlobalTypeFields}
            className="block flex-1 border-1 border-slate-200 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
          <button
            type="button"
            id="btnGlobaltype"
            onClick={moreGlobalType}
            className="e-btn bg-zinc-200 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded"
          >
            Add or Edit Printable Categories
          </button>
        </div>
      </form>
      {entityIID === "new" ? (
        <></>
      ) : (
        <div>
          <div className="mt-5 grid grid-cols-6 gap-x-1 gap-2">
            <h2 className="text-2xl font-extrabold tracking-tight col-span-2">
              Items
            </h2>
            <button
              className="e-btn bg-yellow-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded"
              onClick={optButton}
            >
              Buttons
            </button>
            <button
              className="e-btn bg-green-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded"
              onClick={entityNew}
            >
              New
            </button>
            <button
              className="e-btn bg-sky-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded"
              onClick={entityChange}
            >
              Edit
            </button>
            <button
              className="e-btn bg-red-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded"
              onClick={DeleteConfirm}
            >
              Delete
            </button>
          </div>

          {visButon ? (
            <GridContextProvider onChange={onChange}>
              <GridDropZone
                id="items"
                className="dropzone"
                boxesPerRow={refColumns.current.text}
                rowHeight={100}
              >
                {EntityButtonData.map((item) => (
                  <GridItem key={item.IID}>
                    <div className="grid-item">
                      <div
                        onMouseDown={clickSortItem}
                        id={item.IID}
                        className="grid-item-content"
                        style={{
                          backgroundColor: item.ButtonColor,
                          color: item.ForeColor + "!importtant",
                        }}
                      >
                        {item.EntityButtonName}
                      </div>
                    </div>
                  </GridItem>
                ))}
              </GridDropZone>
            </GridContextProvider>
          ) : (
            <GridComponent
              ref={gridcomp}
              allowPaging
              allowSorting
              dataSource={EntityButtonData}
            >
              <ColumnsDirective>
                {entityButtonGrid.map((item, index) => (
                  <ColumnDirective key={index} {...item} />
                ))}
              </ColumnsDirective>
              <Inject services={[Resize, Sort, Filter, Page, Edit, Toolbar]} />
            </GridComponent>
          )}
        </div>
      )}
    </div>
  );
};

export default POSButton;
