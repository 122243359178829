import React, { useState }  from "react";
import { useEffect, useRef } from 'react';
import { DashboardLayoutComponent, PanelDirective, PanelsDirective } from '@syncfusion/ej2-react-layouts';
import { MdCalculate,MdOutlinePayments } from "react-icons/md";
import { GoListOrdered } from "react-icons/go";

import { Browser } from '@syncfusion/ej2-base';

import { ordersForDashboard } from "../data/dashboard";

import { useStateContext } from "../contexts/ContextProvider";

import { 
  BarSeries,
  ChartComponent, 
  SeriesCollectionDirective, 
  SeriesDirective, 
  Inject, 
  ChartTheme, 
  Legend, 
  Category, 
  Tooltip, 
  ColumnSeries, 
  ILoadedEventArgs, 
  DataLabel, 
  Highlight } from '@syncfusion/ej2-react-charts';

import './CSS/dashboard.css';

const Dashboard = () => {
    const { currentColor, currentMode } = useStateContext();

    const dashboardObj = useRef(null);
    const cellSpacing = [4, 4];
 


    const [paidOrders, setPaidOrders] = useState(0);
    const [unpaidOrders, setUnpaidOrders] = useState(0);



    const [TaxPercentage, setTaxPercentage] = useState([]);
    const [TopTeen, setTopTeen] = useState([]);
    const [OrderTotal, setOrderTotal] = useState({"Unpaid":0,"Paid":0});
    const [Payments, setPayments] = useState({"Cash":0,"Card":0,"Cheque":0});


      useEffect(() => {
        ordersForDashboard((data)=>{
          setOrderTotal(data.OrderTotal);
          setPayments(data.Payments);
          setTaxPercentage(data.TaxPercentage);
          setTopTeen(data.TopTeen);
        });/**/
      }, []);



    const orderTotal = () => {
      return(
            <div className="card">
                <span className="e-icons "><GoListOrdered /></span>
                <div className="card-content text">Paid Total :
                <span className="card-content number"> {OrderTotal.Paid.toFixed(2)}</span></div>
                <div className="card-content text">Unpaid Orders:
                <span className="card-content number">  {OrderTotal.Unpaid.toFixed(2)}</span></div>
                <div className="card-content text">Total Orders:
                <span className="card-content number"> {(OrderTotal.Paid+OrderTotal.Unpaid).toFixed(2)}</span></div>
            </div>
        );
    }
    
    const sessionTotal = () => {
      return(
            <div className="card">
                <span className="e-icons "><MdCalculate /></span>
                {TaxPercentage.map((item, index) => (
                  <div key={index}  className="card-content text">{item.TaxPercent}% : 
                  <span key={index}  className="card-content number"> £{item.total.toFixed(2)}</span></div>
                ))}
            </div>
        );
    }
    
    const PaymentType = () => {
      return(
            <div className="card">
                <span className="e-icons "><MdOutlinePayments /></span>  
                <div className="card-content text">Card Payment :
                <span className="card-content number"> £{Payments.Card.toFixed(2)}</span></div>
                <div className="card-content text">Cash Payment :
                <span className="card-content number"> £{Payments.Cash.toFixed(2)}</span></div>
                <div className="card-content text">Cheque Payment :
                <span className="card-content number"> £{Payments.Cheque.toFixed(2)}</span></div>
            </div>
        );
    }


    const load = (args) => {
      let selectedTheme = 'Fluent2';
      
      args.chart.theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast').replace(/-highContrast/i, 'HighContrast');
      args.chart.series[0].marker.dataLabel.font.color = '#000000';
      
    }

    const MostSaleItems = () => {
      return(
      <div style={{ height: "100%", width: "100%" }}>
         <ChartComponent id='charts' style={{ textAlign: "center" }} 
         legendSettings={{ enableHighlight: true }} 
         primaryXAxis={{ valueType: 'Category', majorGridLines: { width: 0 } }} 
         primaryYAxis={{ 
              labelFormat: '{value}', title: 'Todays Sales', 
              edgeLabelPlacement: 'Shift', majorTickLines: { width: 0 }, lineStyle: { width: 0 } }} 
              chartArea={{ border: { width: 0 } }} load={load.bind(this)} 
              width={Browser.isDevice ? '100%' : '75%'} title='' 
              tooltip={{ enable: false }}>
              <Inject services={[BarSeries, DataLabel, Category, Legend, Tooltip, Highlight]}/>
              <SeriesCollectionDirective >
                  <SeriesDirective dataSource={TopTeen} xName='OrderItemText' yName='MainQ' type='Bar' columnSpacing={0} name='' width={1} 
                  marker={{dataLabel: {visible: true, position: 'Outer', }}} />                  
              </SeriesCollectionDirective>
          </ChartComponent>
      </div>)
    }









    return (<div>
      <div id="default_target" className="control-section">
         
      <DashboardLayoutComponent id="analytic_dashboard" cellAspectRatio={100/85} cellSpacing={cellSpacing} columns={3}>
            <PanelsDirective>
              <PanelDirective sizeX={1} sizeY={1} row={0} col={0} content={orderTotal} header="<div>Orders</div>"></PanelDirective>
              <PanelDirective sizeX={1} sizeY={1} row={0} col={1} content={sessionTotal} header="<div>Calculated TAX</div>"></PanelDirective>
              <PanelDirective sizeX={1} sizeY={1} row={0} col={2} content={PaymentType} header="<div>Payment</div>"></PanelDirective>              
              <hr />
              <PanelDirective sizeX={2} sizeY={2} row={1} col={0} content={MostSaleItems} header="<div>Items</div>"></PanelDirective>              
            </PanelsDirective>
          </DashboardLayoutComponent>
      </div>
  </div>);
};
export default Dashboard;