import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColorPickerComponent } from '@syncfusion/ej2-react-inputs';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import validator from "validator";
import { Header } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";

import {
        entityData,
        entityButtonsData,
        entityButtonData, 
        entityButtonEdit, 
        entityButtonAdd, 
        tileRender,
        changePaletteColour} from "../../data/posmenu";
        
import { MiddleColours,DarkColours } from "../../tools/menuEntity";

import "./../CSS/POSMenu.css"
import { ShowToast } from "../../tools/extras";

const EntityButtonSub = () => {
    const refEntity = useRef();
    const refBColour = useRef();
    const refTColour = useRef();
    const refTVat = useRef();
    const refHVat = useRef();

    const [EntityButtonsData, setEntityButtons] = useState([]);
    const [EntityButtonData, setEntityButton] = useState([]);

    const [selectedColour, setSelectedColour] = useState('#eeeeee');
    const [selectedTColour, setSelectedTColour] = useState('#000000');


    const { entityIID, entityButtonIID, entityButtonSubIID } = useParams();
    const navigate = useNavigate();
    const [selectedEntity, setSelectedEntity] = useState({});
    const [selectedEntityButton, setselectedEntityButton] = useState({});
    const [selectedCatalogue, setSelectedCatalogue] = useState('');

    const { currentMode } = useStateContext();
    const bgcolor = currentMode === "Dark" ? "bg-gray-700" : "bg-white";

    let EntityButtonFields = { text: "EntityButtonName", value: "IID" };

    let GetEntityButtons = () => {
        entityButtonsData(entityIID, (dataorder) => {
            setEntityButtons(dataorder);
            setselectedEntityButton(dataorder.find(item => item.IID === entityButtonIID));
            setSelectedCatalogue(dataorder[0].CatalogIID);
            entityData(entityIID, (dataorder) => {
                setSelectedEntity(dataorder);
            });            
        });
    }


    let GetEntityButton = () => {
        if (entityButtonSubIID !== undefined && entityButtonSubIID!=='new') {
            entityButtonData(entityButtonSubIID, (dataorder) => {
                setEntityButton(dataorder);
                setSelectedColour(dataorder.ButtonColor);
                setSelectedTColour(dataorder.ForeColor);
                //setButtonType(dataorder.ButtonType);

                GetEntityButtons();
                refTVat.current.checked=(dataorder.DirectSaleTaxPercent>0?true:false);
                refHVat.current.checked=(dataorder.InHouseTaxPercent>0?true:false);
            });
        }else{
            GetEntityButtons();
        }
        
    }

    useEffect(() => {
        GetEntityButton();        
    }, []);


    const saveEntityButton = () => {
        var obj = validateModalItems();
        

        if (obj.EntityButtonName === undefined) return false;

        if (entityButtonSubIID !== 'new') {//record check
            entityButtonEdit(obj,
                (x) => {
                    GetEntityButton();  
                    navigate("/posbutton/" + entityIID+"/" + entityButtonIID+"/" + entityButtonSubIID);
                    ShowToast({content:`${obj.EntityButtonName} is changed`,type:'success'})
                });

        } else {
            entityButtonAdd(obj,
                (x) => {
                    GetEntityButton();  
                    navigate("/posbutton/" + entityIID+"/" + entityButtonIID+"/" + x.iid);
                    ShowToast({content:`${obj.EntityButtonName} is added`,type:'success'})
                });
        }
    }
    const copyEntityButton=()=>{
        var obj = validateModalItems();
        ShowToast({content:` its not ready yet`,type:'warning'});

    }

    const cancelEntityButton = () => {
        navigate("/posbutton/" + entityIID+"/" + entityButtonIID);
    }


    const cleanValidateModalItems = () => {
        document.getElementById('errItemName').innerText=('');
        document.getElementById('errPrice').innerText = ('');
    }

    const validateModalItems = () => {
        cleanValidateModalItems();
        let obj = {
            IID : entityButtonSubIID || '',
            EntityButtonName : document.getElementById('itemName').value.trim(),
            ButtonColor : refBColour.current.value,
            ForeColor : refTColour.current.value,
            ButtonType : 0,
            ParentEntityButtonIID:entityButtonIID,
            ParentEntityIID : '', 
            ParentCatalogIID:selectedCatalogue,           
            DirectSaleTaxPercent : refTVat.current.checked? 20: 0, // need to be fixed. for now i set 20 percent manuelly.
            InHouseTaxPercent : refHVat.current.checked? 20: 0, // but it has to be getting from db or somewhere
            DirectSalePrice : document.getElementById('itemTPrice').value.trim(),
            InHousePrice : document.getElementById('itemHPrice').value.trim(),
            PadFlag:0,            
        };    
        let err = false;

        if (!validator.isLength(obj.EntityButtonName, { min: 3, max: 20 })) {
            document.getElementById('errItemName').innerText = ('Item can only be Alpha Numeric characters. Min:3 and Max:20 characters');
            err = true;
        }
        if(!validator.isNumeric(obj.DirectSalePrice) ||  !validator.isNumeric(obj.InHousePrice) ){
            document.getElementById('errPrice').innerText = ('Please check out prices');
            err = true;
        }
        return err ? {} : obj;
    }

    return (
        <div className={"mx-5 rounded-2xl " + bgcolor} >
            <Header title={selectedEntity.EntityName + " - " +  selectedEntityButton.EntityButtonName} />
            <form id="form1">
                <div className="mt-10 grid grid-cols-3 gap-x-6 gap-2">
                <button type="button" id="btnCancelEntity" onClick={cancelEntityButton}
                        className="e-btn bg-zinc-200 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded "
                    >BACK </button>
                    <button type="button" id="btnSaveEntity" onClick={saveEntityButton}
                        className="e-btn bg-zinc-200 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded "
                    >SAVE </button>
                    <button type="button" id="btnSaveEntity" onClick={copyEntityButton}
                        className="e-btn bg-zinc-200 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded "
                    >save copy </button>

                    <label htmlFor="itemName"
                        className="block text-sm font-medium leading-6 text-gray-900 ">
                        Item Name
                    </label>
                    <input id="itemName" type="text" defaultValue={EntityButtonData.EntityButtonName}
                        className="block flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 col-span-2 " >
                    </input>
                    <div id="errItemName" className="col-span-3 text-red-700" />


                    
                    <label htmlFor="VAT"
                        className="block text-sm font-medium leading-6 text-gray-900 ">
                       VAT
                    </label>
                    <div className="block">
                        <span>Take Away</span>
                        <SwitchComponent ref={refTVat} onLabel="VAT" offLabel="NoVAT" checked={false} cssClass="handle-text block" />
                    </div>
                    <div className="block">
                        <span >In House</span>
                        <SwitchComponent ref={refHVat} onLabel="VAT" offLabel="NoVAT" checked={false} cssClass="handle-text block" />
                    </div>

                    <label htmlFor="bcolour"
                        className="block text-sm font-medium leading-6 text-gray-900 ">
                        Colours
                    </label>
                    <div>Button Colour
                        <div id="bcolour" className="colorPalette w-20 border rounded-lg  " style={{ backgroundColor: selectedColour }}>
                        
                            <ColorPickerComponent ref={refBColour}
                                presetColors={MiddleColours} columns={7} mode='Palette' modeSwitcher={false} showButtons={false} value={selectedColour}
                                onChange={changePaletteColour.bind(this)} beforeTileRender={tileRender} />
                        </div>
                    </div>
                    <div>Text Colour
                        <div id="tcolor" className="colorPalette w-20 border rounded-lg " style={{ backgroundColor: selectedTColour }}>                    
                            <ColorPickerComponent ref={refTColour}
                                presetColors={DarkColours} columns={7} mode='Palette' modeSwitcher={false} showButtons={false} value={selectedTColour}
                                onChange={changePaletteColour.bind(this)} beforeTileRender={tileRender} />
                        </div>
                    </div>
                    
                    <label htmlFor="Entity"
                        className="block text-sm font-medium leading-6 text-gray-900 ">
                        Parent Button Name
                    </label>

                    <DropDownListComponent ref={refEntity} id="Entity" dataSource={EntityButtonsData}  value={selectedEntityButton.IID} fields={EntityButtonFields} cssClass="w-1/2  min-w-48"
                        className="block flex-1 border-1 border-slate-200 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm " />
                    <div></div>

                    <hr className=" col-span-3"/>

                    <label htmlFor="Price"
                        className="block text-sm font-medium leading-6 text-gray-900 ">
                       Prices
                    </label>
                    <div className="block">Take Away : 
                    <input id="itemTPrice" type="text" defaultValue={EntityButtonData.DirectSalePrice}
                        className="inline flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 sm:leading-6 w-1/4 min-w-16" >
                    </input>
                    </div>
                    <div className="block">In House : 
                        <input id="itemHPrice" type="text" defaultValue={EntityButtonData.InHousePrice}
                            className="inline flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 sm:leading-6 w-1/4 min-w-16" >
                        </input>
                    </div>                    
                    
                    <div id="errPrice" className="col-span-3 text-red-700" />

                    
                </div>                
            </form><br/>

        </div>


    );
}


export default EntityButtonSub;