// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .dropzone {
    flex: 1 1;
    height: 300px;
    border: 1px dashed rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
  }
  
  .grid-item {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.1em;
    cursor: move;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);

  }
  
  .btnBorder {
    border-image: linear-gradient(to top, #ccc 0%, #333 100%) 1!important;
    border-width: 4px!important;
    padding: 0!important;
  }
  
  .grid-item-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    text-align: center; 
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    overflow-wrap: anywhere;
    border-radius: 0.5em;
    text-shadow: .5px .5px #999;
    -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    
    /* border: border */
    /*border-image: rgba(0, 0, 0, 0.0);*/
    border-style: solid;
    padding: 4px;
    margin: 0;

  }


  `, "",{"version":3,"sources":["webpack://./src/pages/CSS/dnd.css"],"names":[],"mappings":"EAAE;IACE,SAAO;IACP,aAAa;IACb,qCAAqC;IACrC,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,cAAc;IACd,YAAY;IACZ,0CAA0C;;EAE5C;;EAEA;IACE,qEAAqE;IACrE,2BAA2B;IAC3B,oBAAoB;EACtB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;IACX,yCAAyC;IACzC,mBAAmB;IACnB,uBAAuB;IACvB,oBAAoB;IACpB,2BAA2B;IAC3B,yBAAyB,EAAE,WAAW,EACf,oBAAoB;IAC3C,iBAAiB,EAAE,oBAAoB;;IAEvC,mBAAmB;IACnB,oCAAoC;IACpC,mBAAmB;IACnB,YAAY;IACZ,SAAS;;EAEX","sourcesContent":["  .dropzone {\r\n    flex: 1;\r\n    height: 300px;\r\n    border: 1px dashed rgba(0, 0, 0, 0.1);\r\n    border-radius: 1rem;\r\n  }\r\n  \r\n  .grid-item {\r\n    width: 100%;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n    padding: 0.1em;\r\n    cursor: move;\r\n    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);\r\n\r\n  }\r\n  \r\n  .btnBorder {\r\n    border-image: linear-gradient(to top, #ccc 0%, #333 100%) 1!important;\r\n    border-width: 4px!important;\r\n    padding: 0!important;\r\n  }\r\n  \r\n  .grid-item-content {\r\n    width: 100%;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n    display: flex;\r\n    justify-content: center;\r\n    text-align: center; \r\n    color: #000;\r\n    font-family: Arial, Helvetica, sans-serif;\r\n    align-items: center;\r\n    overflow-wrap: anywhere;\r\n    border-radius: 0.5em;\r\n    text-shadow: .5px .5px #999;\r\n    -webkit-user-select: none; /* Safari */\r\n    -ms-user-select: none; /* IE 10 and IE 11 */\r\n    user-select: none; /* Standard syntax */\r\n    \r\n    /* border: border */\r\n    /*border-image: rgba(0, 0, 0, 0.0);*/\r\n    border-style: solid;\r\n    padding: 4px;\r\n    margin: 0;\r\n\r\n  }\r\n\r\n\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
