import React,{useEffect,useState,useRef} from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Sort,
  Filter,
  Page,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { DialogComponent,DialogUtility } from '@syncfusion/ej2-react-popups';

import { staffData, staffGrid,staffLevel,staffEdit,staffAdd,staffDelete } from "../data/staff";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import validator from "validator";


const Staff = () => {
  //let  gridcomp;
  //let confirmObj;

  const [StaffD, setStaffD] = useState([]);
  const gridcomp = useRef();
  const confirmObj = useRef();

  const [visibility, setDialogVisibility] = useState(false);
  const [dialogHeader,setDialogHeader] = useState("");

  const { currentMode } = useStateContext();
  const editSettings = {allowEditOnDblClick: false};
  
  const bgcolor = currentMode === "Dark" ? "bg-gray-700" : "bg-white";

  const staffChange=()=>{ 
    if(gridcomp.current.selectedRowIndex>=0){      
      setDialogHeader("Change Staff Details");
      let selectedRow = gridcomp.current.getSelectedRecords()[0];      
      document.getElementById('username').value=selectedRow.UserName; 
      document.getElementById('password').value=selectedRow.UserPassword; 
      document.getElementById('level').value=selectedRow.AccessLevel; 
      document.getElementById('staffIID').value=selectedRow.IID;     
      showStaffModal();
    }
  }
  
  const staffNew=()=>{
    setDialogHeader("Add a New Staff Details");
    showStaffModal();
    cleanModalItems();
  }

  const saveStaff=()=>{    
    var obj = validateModalItems();
    if(obj.UserName===undefined) return false;

    if(gridcomp.current.selectedRowIndex>=0) {
      staffEdit(obj.IID,obj.UserName,obj.UserPassword,obj.AccessLevel,(x)=>{
        dialogClose();
        getStaff();
      });
    }else{      
      staffAdd(obj.UserName,obj.UserPassword,obj.AccessLevel,(x)=>{
        dialogClose();
        getStaff();
      });
    }
  }
  const staffDeleteConfirm = ()=>{
      confirmObj.current = DialogUtility.confirm({      
        //cancelButton: { text: 'Cancel', click: ()=>{console.log("gave up")} },
        closeOnEscape: false,
        content: "When you delete it, there is no option to get it back. Please think twice before deleting anything!",
        okButton: { text: 'DELETE IT', click: staffDeleteEvent.bind(this) },
        showCloseIcon: true,
        title: 'Do you really want to delete the staff?',

      });    
  }
  const staffDeleteEvent = ()=>{
    if(gridcomp.current.selectedRowIndex>=0){      
        let selectedRow = gridcomp.current.getSelectedRecords()[0];
        staffDelete(selectedRow.IID,()=>{          
          getStaff();
          confirmObj.current.hide();
      });
    }
  }

  
  const showStaffModal=()=>{
    var modal = document.getElementById('btnSaveStaff');    
    if(modal!==undefined && modal.getAttribute('listener') !== 'true') {
      modal.addEventListener('click',saveStaff);
      modal.setAttribute('listener', 'true');
    }
    cleanValidateModalItems();
    setDialogVisibility(true);
  }
  useEffect(() => {
    getStaff();
  },[]);

  const dialogClose=()=> {
    setDialogVisibility(false);
  }


  const cleanValidateModalItems=()=>{
    document.getElementById('errPassword').innerText=('');
    document.getElementById('errUserName').innerText=('');
  }
  const cleanModalItems=()=>{
    document.getElementById('username').value=''; 
    document.getElementById('password').value=''; 
    document.getElementById('level').value=0; 
  }

  const validateModalItems=()=>{
    let obj = {IID:'',UserName:'',UserPassword:'',AccessLevel:0};
    obj.UserName = document.getElementById('username').value.trim()||'staff'; 
    obj.UserPassword = document.getElementById('password').value; 
    obj.AccessLevel = document.getElementById('level').value||0; 
    obj.IID = document.getElementById('staffIID').value||''; 
    let err=false;

    if(! validator.isLength(obj.UserName,{ min: 1, max: 10 }) || ! validator.isAlphanumeric(obj.UserName) ){
      document.getElementById('errUserName').innerText=('Username can only be Alpha Numeric characters. Min:1 and Max:10 characters');
      err=true;
    }
    if(! validator.isInt(obj.UserPassword,{ min: 0, max: 99999999 }) ){
      document.getElementById('errPassword').innerText=('Password has to be number only. Min:1 and Max:8 digit');
      err=true;
    }
    return err?{}:obj;
  }


    

  
  let getStaff = () => {
    setStaffD([]);//clean the grid before loading      
    staffData((dataorder)=>{ 
      setStaffD(dataorder);// leading data to the grid
    });     
  }

  return(
    <div className={"mx-5 rounded-2xl " + bgcolor} >
    <Header title="Staff" />   

    <div className="grid grid-cols-6 gap-2">       
      <div className="col-span-5">
        <button className="e-btn bg-teal-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded mr-1" onClick={getStaff} >LOAD STAFF</button>
        <button className="e-btn bg-green-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded mr-1" onClick={staffNew}    >NEW STAFF</button>
        <button className="e-btn bg-sky-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded mr-1" onClick={staffChange}   >EDIT</button>
        <button className="e-btn bg-red-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded mr-1" onClick={staffDeleteConfirm}  >DELETE STAFF</button>
      </div>
        <div className="col-span-1 grid grid-cols-2 gap-0.5">
        </div>
      </div>  
      <br/>
      <GridComponent ref={gridcomp} allowPaging={true} allowSorting={true}  editSettings={editSettings}  dataSource={StaffD} >
        <ColumnsDirective>
          {staffGrid.map((item, index) => (
            <ColumnDirective key={index} {...item}  />
          ))}
        </ColumnsDirective>
        <Inject services={[Sort,  Filter, Page,  Toolbar]} />
      </GridComponent>
      
      <DialogComponent isModal={true}  id="modalStaff" showCloseIcon={true} visible={visibility}  header={dialogHeader}  close={dialogClose} 
      cssClass="e-fixed border-8 border-zing-500" width='450px' >
        <div >
        <form id="form1">
        <div className="mt-10 grid grid-cols-3 gap-x-6 gap-2">   
          <label htmlFor="username" 
          className="block text-sm font-medium leading-6 text-gray-900 ">
            User Name
          </label>
          <input id="username" type="text" 
            className="block flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 col-span-2 " >
            </input>
          <div id="errUserName" className="col-span-3 text-red-700"/> 
          
          
          <label htmlFor="password" 
            className="block text-sm font-medium leading-6 text-gray-900 ">
            Password
          </label>
            <input id="password" type="password"
            className="block flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 col-span-2 " >
            </input> 
          
          <div id="errPassword" className="col-span-3 text-red-700"/> 

          <label htmlFor="level" 
            className="block text-sm font-medium leading-6 text-gray-900 ">
            Access Level 
          </label>
          <select  id="level" type="text" 
          className="block flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 col-span-2 " >                
          {Object.keys(staffLevel).map(key => <option key={key} value={key} >{staffLevel[key].Status}</option>)}
          </select>
          
          <button type="button" id="btnSaveStaff" 
            className="bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-zing-500 hover:border-transparent rounded w-90 col-span-3"
            >SAVE STAFF</button>
          </div>
          <input type="hidden" id="staffIID" />
            </form>     
            </div>  
        </DialogComponent>

    </div>
  )
}

export default Staff;
