import React from "react";

import {fullServerAddress} from "../tools/config"
import {toDecimal,toCurrency} from "../tools/currency"



export const ordersForDashboard = (fn=()=>{})=>{  
    fetch(fullServerAddress + '/api/orders/getOrdersForDashboard', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((res) => res.json())
        .then((data) => {
            fn(data);
         })
       .catch(err => {
          //ShowToast('server Error!!')
        });
}