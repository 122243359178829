import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  Filter,
  Page,
  Edit,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { DialogUtility } from "@syncfusion/ej2-react-popups";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import validator from "validator";
import { Header } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";

import {
  entitiesData,
  entityData,
  entityButtonGrid,
  entityButtonData,
  entityButtonEdit,
  entityButtonAdd,
  entityButtonDelete,
  tileRender,
  changePaletteColour,
  sortButtonEntities,
  entityButtonSubsData,
} from "../../data/posmenu";
import {
  MenuColumns,
  MiddleColours,
  DarkColours,
  VAT,
  ButtonTypes,
  ButtonDiscTypes,
  PAD,
} from "../../tools/menuEntity";

import "./../CSS/POSMenu.css";
import { ShowToast } from "../../tools/extras";

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import "./../CSS/dnd.css";

const EntityButtonMore = () => {
  const gridcomp = useRef();
  const refEntity = useRef();
  const refBColour = useRef();
  const refTColour = useRef();
  const refTVat = useRef();
  const refHVat = useRef();
  const refButtonType = useRef();
  const confirmObj = useRef();

  const [EntityData, setEntity] = useState([]);
  const [EntityButtonData, setEntityButton] = useState([]);
  const [EntityButtonSubsData, setEntityButtonSubs] = useState([]);
  const [buttonTypes, setButtonTypes] = useState([]);

  const [selectedColour, setSelectedColour] = useState("#eeeeee");
  const [selectedTColour, setSelectedTColour] = useState("#000000");
  const [selectedButtonType, setButtonType] = useState(0);

  const { entityIID, entityButtonIID } = useParams();
  const navigate = useNavigate();
  const [selectedEntity, setSelectedEntity] = useState({});
  const [selectedCatalogue, setSelectedCatalogue] = useState("");
  const [refColumns, setRefColumns] = useState(4);

  const { currentMode } = useStateContext();
  const bgcolor = currentMode === "Dark" ? "bg-gray-700" : "bg-white";

  let EntityFields = { text: "EntityName", value: "IID" };
  let fields = { text: "text", value: "value" };

  let GetEntity = (catalogIID, ButtonType = 0) => {
    entitiesData(catalogIID, (dataorder) => {
      setEntity(dataorder);
      let c = MenuColumns.find((item) => item.value == dataorder.ButtonWidth);
      //setRefColumns(c===null?4:c.text);

      let selectedEntityType = dataorder.find((item) => item.IID === entityIID);
      setSelectedEntity(selectedEntityType);
      console.log(selectedEntityType);
      if (selectedEntityType.EntityType === 1) {
        setButtonTypes(ButtonDiscTypes);
      } else {
        setButtonTypes(ButtonTypes);
      }
      setButtonType(ButtonType);
    });
  };

  const GetEntityButton = () => {
    if (entityButtonIID !== "new") {
      entityButtonData(entityButtonIID, (dataorder) => {
        setEntityButton(dataorder);
        GetEntity(dataorder.CatalogIID, dataorder.ButtonType);

        setSelectedColour(dataorder.ButtonColor);
        setSelectedTColour(dataorder.ForeColor);

        MenuItemCheck(dataorder.PadFlag);
        refTVat.current.checked =
          dataorder.DirectSaleTaxPercent > 0 ? true : false;
        refHVat.current.checked =
          dataorder.InHouseTaxPercent > 0 ? true : false;

        if (dataorder.ButtonType === 1) {
          GetEntityButtonSubData();
        }
        setSelectedCatalogue(dataorder.CatalogIID);
      });
    } else {
      MenuItemCheck();
      entityData(entityIID, (dataorder) => {
        GetEntity(dataorder.CatalogIID);
        setSelectedCatalogue(dataorder.CatalogIID);
      });
    }
  };
  const GetEntityButtonSubData = () => {
    if (entityButtonIID !== "new")
      entityButtonSubsData(entityButtonIID, (subdataorder) => {
        setEntityButtonSubs(subdataorder);
      });
  };

  useEffect(() => {
    GetEntityButton();
  }, []);

  const saveEntityButton = () => {
    var obj = validateModalItems();

    if (obj.EntityButtonName === undefined) return false;

    if (entityButtonIID !== "new") {
      //record check
      entityButtonEdit(obj, (x) => {
        //getEntity();
        navigate("/posbutton/" + entityIID + "/" + entityButtonIID);
        ShowToast({
          content: `${obj.EntityButtonName} is changed`,
          type: "success",
        });
      });
    } else {
      entityButtonAdd(obj, (x) => {
        //getEntity();
        navigate("/posbutton/" + entityIID + "/" + x.iid);
        ShowToast({
          content: `${obj.EntityButtonName} is added`,
          type: "success",
        });
      });
    }
  };
  const copyEntityButton = () => {
    ShowToast({ content: ` its not ready yet`, type: "warning" });
    /*
        var obj = validateModalItems();
        if (obj.EntityButtonName === undefined) return false;
        entityButtonAdd(obj,
            (x) => {
                //getEntity();
                navigate("/posbutton/" + entityIID);//sub items and prices have to be copied... dont forget
            });
            */
  };

  const cancelEntityButton = () => {
    //console.log(entityIID)
    navigate("/posmenu/" + selectedCatalogue + "/" + entityIID + "/");
  };

  const MenuItemCheck = (PadFlag = 0) => {
    if (PadFlag === 1) {
      document.getElementById("MenuItem").checked = false;
      document.getElementById("PADItem").checked = true;
    } else {
      document.getElementById("MenuItem").checked = true;
      document.getElementById("PADItem").checked = false;
    }
  };

  const cleanValidateModalItems = () => {
    document.getElementById("errItemName").innerText = "";
    document.getElementById("errPrice").innerText = "";
  };

  const validateModalItems = () => {
    cleanValidateModalItems();
    let obj = {
      IID: entityButtonIID || "",
      EntityButtonName: document.getElementById("itemName").value.trim(),
      ButtonColor: refBColour.current.value,
      ForeColor: refTColour.current.value,
      ButtonType: selectedButtonType,
      ParentEntityButtonIID: "",
      ParentEntityIID: entityIID,
      ParentCatalogIID: selectedCatalogue,
      DirectSaleTaxPercent: refTVat.current.checked ? 20 : 0, // need to be fixed. for now i set 20 percent manuelly.
      InHouseTaxPercent: refHVat.current.checked ? 20 : 0, // but it has to be getting from db or somewhere
      DirectSalePrice: document.getElementById("itemTPrice").value.trim(),
      InHousePrice: document.getElementById("itemHPrice").value.trim(),
      PadFlag: document.querySelector('input[name="ItemType"]:checked').value,
    }; //console.log(obj);
    obj.IID = entityButtonIID || "";
    let err = false;

    if (!validator.isLength(obj.EntityButtonName, { min: 3, max: 20 })) {
      document.getElementById("errItemName").innerText =
        "Please check out the name field. Min:3 and Max:20 characters";
      err = true;
    }
    if (
      !validator.isNumeric(obj.DirectSalePrice) ||
      !validator.isNumeric(obj.InHousePrice)
    ) {
      document.getElementById("errPrice").innerText = "Please check out prices";
      err = true;
    }
    return err ? {} : obj;
  };
  const ButtonTypesChanges = (args) => {
    setButtonType(args.value);
  };

  const entityChange = () => {
    if (gridcomp.current != null && gridcomp.current.selectedRowIndex > -1) {
      selectedRow = gridcomp.current.getSelectedRecords()[0];
    }
    if (selectedRow != null && entityIID !== "new") {
      let selectedSubButton = gridcomp.current.getSelectedRecords()[0];
      navigate(
        "/posbutton/" +
          entityIID +
          "/" +
          entityButtonIID +
          "/" +
          selectedSubButton.IID
      );
    } else {
      ShowToast({ content: `Please select an item first` });
    }
  };

  const entityNew = () => {
    if (entityButtonIID !== "new")
      navigate("/posbutton/" + entityIID + "/" + entityButtonIID + "/new");
  };

  const EntityButtonDel = () => {
    if (gridcomp.current != null && gridcomp.current.selectedRowIndex > -1) {
      selectedRow = gridcomp.current.getSelectedRecords()[0];
    }
    if (selectedRow != null && entityButtonIID !== "new") {
      let selectedSubButton = gridcomp.current.getSelectedRecords()[0];
      entityButtonDelete(selectedSubButton.IID, () => {
        GetEntityButtonSubData();
        confirmObj.current.hide();
        ShowToast({ content: `It's deleted!`, type: "success" });
      });
    }
  };
  const DeleteConfirm = () => {
    if (gridcomp.current != null && gridcomp.current.selectedRowIndex > -1) {
      selectedRow = gridcomp.current.getSelectedRecords()[0];
    }
    if (selectedRow != null && entityButtonIID !== "new") {
      confirmObj.current = DialogUtility.confirm({
        //cancelButton: { text: 'Cancel', click: ()=>{console.log("gave up")} },
        closeOnEscape: false,
        content:
          "When you delete it, there is no option to get it back. Please think twice before deleting anything!",
        okButton: { text: "DELETE IT", click: EntityButtonDel.bind(this) },
        showCloseIcon: true,
        title: "Do you really want to delete it?",
      });
    } else {
      ShowToast({ content: `Please select an item first` });
    }
  };

  /*menu Drag Drop Start*/
  const [visButon, setVisButon] = useState(false);
  let selectedRow = null;
  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    if (sourceIndex == targetIndex) return;
    console.log(sourceIndex, targetIndex);
    const nextState = swap(EntityButtonSubsData, sourceIndex, targetIndex);
    setEntityButton(nextState);
    gridcomp.dataSource = nextState;
    sortButtonEntities(
      nextState.map((item, index) => {
        return { IID: item.IID, DisplayOrder: index };
      })
    );
  }
  var selectedBtn = null;
  function clickSortItem(event) {
    document
      .querySelectorAll(".grid-item-content")
      .forEach((element) => element.classList.remove("btnBorder"));

    selectedRow = EntityButtonSubsData.find(
      (item) => item.IID == event.target.id
    );
    selectedBtn = event.target;
    selectedBtn.classList.add("btnBorder");
    //console.log(event.target.id,selectedRow);
  }

  const optButton = (event) => {
    if (visButon == false) {
      setVisButon(true);
      event.target.innerText = "List";
    } else {
      setVisButon(false);
      event.target.innerText = "Button";
    }
    selectedRow = null;
  };

  /*menu Drag Drop End*/

  return (
    <div className={"mx-5 rounded-2xl " + bgcolor}>
      <Header
        title={
          selectedEntity.EntityName +
          " - " +
          EntityButtonSubsData.EntityButtonName
        }
      />
      <form id="form1">
        <div className="mt-10 grid grid-cols-3 gap-x-6 gap-2">
          <button
            type="button"
            id="btnCancelEntity"
            onClick={cancelEntityButton}
            className="e-btn bg-zinc-200 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded "
          >
            BACK{" "}
          </button>
          <button
            type="button"
            id="btnSaveEntity"
            onClick={saveEntityButton}
            className="e-btn bg-zinc-200 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded "
          >
            SAVE{" "}
          </button>
          <button
            type="button"
            id="btnSaveEntity"
            onClick={copyEntityButton}
            className="e-btn bg-zinc-200 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded "
          >
            SAVE AS{" "}
          </button>

          <label
            htmlFor="itemName"
            className="block text-sm font-medium leading-6 text-gray-900 "
          >
            Item Name
          </label>
          <input
            id="itemName"
            type="text"
            defaultValue={EntityButtonData.EntityButtonName}
            className="block flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 col-span-2 "
          ></input>
          <div id="errItemName" className="col-span-3 text-red-700" />

          <label
            htmlFor="ButtonType"
            className="block text-sm font-medium leading-6 text-gray-900 "
          >
            Button Type
          </label>

          <div className="e-btn-group ">
            <input type="radio" id="MenuItem" name="ItemType" value="0" />
            <label className="e-btn" htmlFor="MenuItem">
              Menu Item
            </label>
            <input type="radio" id="PADItem" name="ItemType" value="1" />
            <label className="e-btn" htmlFor="PADItem">
              PAD Item
            </label>
          </div>
          <div></div>

          <label
            htmlFor="VAT"
            className="block text-sm font-medium leading-6 text-gray-900 "
          >
            VAT
          </label>
          <div className="block">
            <span>Take Away</span>
            <SwitchComponent
              ref={refTVat}
              onLabel="VAT"
              offLabel="NoVAT"
              checked={false}
              cssClass="handle-text block"
            />
          </div>
          <div className="block">
            <span>In House</span>
            <SwitchComponent
              ref={refHVat}
              onLabel="VAT"
              offLabel="NoVAT"
              checked={false}
              cssClass="handle-text block"
            />
          </div>

          <label
            htmlFor="bcolour"
            className="block text-sm font-medium leading-6 text-gray-900 "
          >
            Colours
          </label>
          <div>
            Button Colour
            <div
              id="bcolour"
              className="colorPalette w-20 border rounded-lg  "
              style={{ backgroundColor: selectedColour }}
            >
              <ColorPickerComponent
                ref={refBColour}
                presetColors={MiddleColours}
                columns={7}
                mode="Palette"
                modeSwitcher={false}
                showButtons={false}
                value={selectedColour}
                onChange={changePaletteColour.bind(this)}
                beforeTileRender={tileRender}
              />
            </div>
          </div>
          <div>
            Text Colour
            <div
              id="tcolor"
              className="colorPalette w-20 border rounded-lg "
              style={{ backgroundColor: selectedTColour }}
            >
              <ColorPickerComponent
                ref={refTColour}
                presetColors={DarkColours}
                columns={7}
                mode="Palette"
                modeSwitcher={false}
                showButtons={false}
                value={selectedTColour}
                onChange={changePaletteColour.bind(this)}
                beforeTileRender={tileRender}
              />
            </div>
          </div>

          <label
            htmlFor="Entity"
            className="block text-sm font-medium leading-6 text-gray-900 "
          >
            Category Name
          </label>

          <DropDownListComponent
            ref={refEntity}
            id="Entity"
            dataSource={EntityData}
            value={selectedEntity.IID}
            fields={EntityFields}
            cssClass="w-1/2  min-w-48"
            className="block flex-1 border-1 border-slate-200 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm "
          />
          <div></div>

          <hr className=" col-span-3" />

          <label
            htmlFor="Price"
            className="block text-sm font-medium leading-6 text-gray-900 "
          >
            Prices
          </label>
          <div className="block">
            Take Away :
            <input
              id="itemTPrice"
              type="text"
              defaultValue={EntityButtonData.DirectSalePrice}
              className="inline flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 sm:leading-6 w-1/4 min-w-16"
            ></input>
          </div>
          <div className="block">
            In House :
            <input
              id="itemHPrice"
              type="text"
              defaultValue={EntityButtonData.InHousePrice}
              className="inline flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 sm:leading-6 w-1/4 min-w-16"
            ></input>
          </div>

          <div id="errPrice" className="col-span-3 text-red-700" />

          <label
            htmlFor="ButtonType"
            className="block text-sm font-medium leading-6 text-gray-900 "
          >
            Button Complexity
          </label>

          <DropDownListComponent
            ref={refButtonType}
            id="ButtonType"
            dataSource={buttonTypes}
            value={selectedButtonType}
            fields={fields}
            onChange={ButtonTypesChanges}
            cssClass="w-1/2  min-w-48"
            className="block flex-1 border-1 border-slate-200 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
          />
          <div></div>
        </div>
      </form>
      <br />
      <hr />
      {selectedButtonType !== 1 || entityButtonIID === "new" ? (
        <></>
      ) : (
        <div>
          <div className="mt-5 grid grid-cols-6 gap-x-1 gap-2">
            <h2 className="text-2xl font-extrabold tracking-tight col-span-2">
              Sub Items
            </h2>
            <button
              className="e-btn bg-yellow-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded"
              onClick={optButton}
            >
              Buttons
            </button>
            <button
              className="e-btn bg-green-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded"
              onClick={entityNew}
            >
              New
            </button>
            <button
              className="e-btn bg-sky-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded"
              onClick={entityChange}
            >
              Edit
            </button>
            <button
              className="e-btn bg-red-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase rounded"
              onClick={DeleteConfirm}
            >
              Delete
            </button>
          </div>

          {visButon ? (
            <GridContextProvider onChange={onChange}>
              <GridDropZone
                id="items"
                className="dropzone"
                boxesPerRow={refColumns}
                rowHeight={100}
              >
                {EntityButtonSubsData.map((item) => (
                  <GridItem key={item.IID}>
                    <div className="grid-item">
                      <div
                        onMouseDown={clickSortItem}
                        id={item.IID}
                        className="grid-item-content"
                        style={{
                          backgroundColor: item.ButtonColor,
                          color: item.ForeColor + "!importtant",
                        }}
                      >
                        {item.EntityButtonName}
                      </div>
                    </div>
                  </GridItem>
                ))}
              </GridDropZone>
            </GridContextProvider>
          ) : (
            <GridComponent
              ref={gridcomp}
              allowPaging
              allowSorting
              dataSource={EntityButtonSubsData}
            >
              <ColumnsDirective>
                {entityButtonGrid.map((item, index) => (
                  <ColumnDirective key={index} {...item} />
                ))}
              </ColumnsDirective>
              <Inject services={[Resize, Sort, Filter, Page, Edit, Toolbar]} />
            </GridComponent>
          )}
        </div>
      )}
    </div>
  );
};

export default EntityButtonMore;
